<template>

<app-item route="Access" :id="item.id" id-name="access">

	<app-item-title :text="item.user.name" :subtitle="item.user.email" />

</app-item>

</template>

<script>

export default {

	props: ['item']

}

</script>

<style scoped>

</style>
